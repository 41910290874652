<template>
  <layout-vertical>
    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <div slot="footer">
      <b-card
        title=""
        class="visibilityDesktop"
      >
        <p>Patrocinadores:</p>
        <!--
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_01.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_02.png"
            style="max-height: 132px; width: auto;"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_03.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_04.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_05.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_06.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_07.png"
          >
        </div>
        -->
      </b-card>
      <b-card
        title=""
        class="visibilityMobile"
      >
        <p id="titleTxt">
          Patrocinadores:
        </p>
        <!--
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_01.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_02.png"
            style="max-height: 132px; width: auto;"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_03.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_04.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_05.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_06.png"
          >
        </div>
        <div class="singleImage">
          <img
            src="@/assets/images/sponsers/congress/sponsors_congress_mobile/marcas_rodape_patrocinadores_cnno_07.png"
          >
        </div>
        -->
      </b-card>
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_08.png">-->
      <!--<img src="@/assets/images/sponsers/congress/marcas_rodape_patrocinadores_cnno_09.png">-->
      <p class="mb-0">
        &copy; {{ client }} {{ new Date().getFullYear() }}
        <span class="float-md-right d-none d-md-block">
          OP3
        </span>
      </p>
    </div>
    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
  </layout-vertical>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import LayoutVertical from '@core/utils/layouts/layout-vertical-admin/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BCard,
  },
  data() {
    return {
      client: '',
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  mounted() {
    this.client = process.env.VUE_APP_CLIENT
  },
}
</script>

<style scoped>
  .singleImage{
    max-height: 132px;
    display: inline-block;
  }
  @media only screen and (max-width: 780px) {
  .singleImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 24em;
  }
  .visibilityDesktop{
    display: none;
    }
  }

  @media (min-width: 781px) {
  .visibilityMobile{
     display: none;
    }
  }
  #titleTxt{
    text-align: center;
  }
  @media only screen and (max-width: 390px) {
  .singleImage{
    width: 80em;
    }
  }
</style>
