// eslint-disable-next-line import/extensions
import store from '@/store'

const { congressAll } = store.state.congress
const congressChil = []

congressAll.forEach((element) => {
  congressChil.push({
    title: `${element.title}`,
    route: {
      name: `${element.path_event}_dashboard_congress_administrator`,
      params: { congress: element.path_event },
    },
    icon: 'TvIcon',
  })
})

const menu = []
/*
menu.push({
  title: 'Home Admin',
  icon: 'HomeIcon',
  route: {
    name: 'admin',
  },
})
*/
menu.push({
  title: 'Inicio',
  icon: 'HomeIcon',
  route: {
    name: 'home_plataforma',
  },
})
menu.push({
  title: 'Congressos',
  icon: 'TvIcon',
  route: {
    name: 'list_congress',
  },
})
menu.push({
  title: 'Congressos dashboard',
  icon: 'TvIcon',
  children: congressChil,
})
menu.push({
  title: 'Cursos',
  icon: 'MonitorIcon',
  route: {
    name: 'courses_admin',
  },
})
menu.push({
  title: 'Lives',
  icon: 'VideoIcon',
  route: {
    name: 'livesAdministrador',
  },
})
menu.push({
  title: 'Podcasts',
  icon: 'HeadphonesIcon',
  route: {
    name: 'podcastAdministrador',
  },
})
menu.push({
  title: 'Revistas',
  icon: 'BookOpenIcon',
  route: {
    name: 'list_magazine_administration',
  },
})
menu.push({
  title: 'Associados',
  icon: 'UserIcon',
  tag: '2',
  tagVariant: 'light-danger',
  children: [
    {
      title: 'Dashboard',
      route: 'dashboard_users_administrator_associate',
    },
    {
      title: 'Lista',
      route: 'list_users_associate',
    },
  ],
})
menu.push({
  title: 'Palestrantes',
  icon: 'UsersIcon',
  route: {
    name: 'list_speaker',
  },
})

menu.push({
  title: 'Personalizar',
  icon: 'BookOpenIcon',
  route: {
    name: 'personalizar',
  },
})

export default menu
